import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog,MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MasterService } from '../../services/master.service';
import { GlobalService } from '../../services/global.service';
import { ItemsRestockConfirmationComponent } from '../items-restock-confirmation/items-restock-confirmation.component';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-confirm-dialog-modal',
  templateUrl: './confirm-dialog-modal.component.html',
  styleUrls: ['./confirm-dialog-modal.component.scss']
})
export class ConfirmDialogModalComponent implements OnInit {
  modalTitle: string;
  public restockAdmin;
  constructor(@Inject(MAT_DIALOG_DATA
    ) public data: any,private dialog: MatDialog, private masterService: MasterService, private globalService: GlobalService,
    private dialogRef: MatDialogRef<ConfirmDialogModalComponent>) { }

  ngOnInit() {
    this.restockAdmin = this.data.restockAdmin;
    if(this.restockAdmin){

    }else{
      this.modalTitle = this.data.title;
      console.log(this.data)
    }
  }

  itemRestockAdmin(){
    this.globalService.showLoader = true;
    console.log("this is data ", this.data);
    
    this.masterService
      .inventoryRestockAdmin({
        LangSave: this.data.LangSave,
        subDomain: this.data.subDomain,
        requestedQuantity: this.data.requestedQuantity,
        quantityAction: this.data.quantityAction,
        purpose: this.data.purpose,
        itemId: this.data.itemId,
        requestedBy: this.data.requestedBy,
        requestType: this.data.requestType,
        restaurantId: this.data.restaurantId,
        serviceId: this.data.serviceId,
        catId: this.data.catId,
        threshold: this.data.threshold
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            // this.globalService.emitEditServiceView(this.data.serviceId)
            // this.globalService.emitEditResturantView(this.data.restaurantId);

            this.closeDialogs();
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  closeDialog(){
    this.dialogRef.close({event:false});
  
  }
  closeDialogs(){
    
    this.dialogRef.close({event:true, requestType: this.data.requestType,serviceId: this.data.serviceId,restaurantId: this.data.restaurantId});
  
  }

  ngOnDestroy(){

  }
}
