import { Component, Renderer2 } from '@angular/core';
import { GlobalService } from './core/services/global.service';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { map } from 'src/environments/map';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  previousUrl: string;
  constructor(
    public globalService: GlobalService,
    private renderer: Renderer2, 
    public router: Router
  ) {
    router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.globalService.showLoader = true;
      }
      if (event instanceof RouteConfigLoadEnd) {
        this.globalService.showLoader = false;
      }
      
      if (this.globalService.getFirstUrlSegment() == '/') {
        this.renderer.removeClass(document.body, 'hotelClass');
      }
    
      if (this.globalService.getFirstUrlSegment() == 'hotel') {
        this.renderer.addClass(document.body, 'hotelClass');
      }
    
    });
  }

  ngOnInit() {
    this.loadGoogleMaps();
  }
  
  loadGoogleMaps() {
    const existingScript = document.getElementById('google-maps-script');
    if (!existingScript) {
      const script = this.renderer.createElement('script');
      script.id = 'google-maps-script';
      script.src = `https://maps.googleapis.com/maps/api/js?key=${map?.googleMapsApiKey}&libraries=places`;
      script.async = true;
      script.defer = true;
      
      this.renderer.appendChild(document.head, script);
    }
  }
 

}
