<app-loader [ShowLoader]="globalService.showLoader"></app-loader>


<div class="single-image" *ngIf="locationStatus === 'imageEdit'">
    <!-- <div class="profile-image">
        <img [src]="imgUrl" height="200" *ngIf="imgUrl">
    </div> -->

    <div class="profile-image custom-is-ch_d" *ngIf="imgUrl">
        <div class="file-upload-wrapper">
            <i *ngIf="imgUrl" class="material-icons remove-img" style="color:red;" aria-hidden="true" (click)="removeSingle(imgUrl)">delete</i>
            <img [src]="imgUrl">
            <div class="uload-image while-edit">
                <div class="upload">
                    <span><i class="material-icons">edit</i></span>
                    <input #file type="file" accept='image/*' (change)="preview(file.files)" />
                </div>
            </div>
            <span style="color:red;" *ngIf="imgErrorMessage">{{imgErrorMessage}}</span>
        </div>
    </div>
    <div class="uload-image upload-image-add" *ngIf="!imgUrl">
        <div class="upload">
            <span>
        <i class="material-icons">attach_file</i>
      </span>
            <input #file type="file" accept='image/*' (change)="preview(file.files)" />
        </div>
        <span style="color:red;" *ngIf="imgErrorMessage">{{imgErrorMessage}}</span>
    </div>
</div>

<div class="single-image" *ngIf="locationStatus == 'singleImage'">
    <div class="profile-image custom-is-ch_e">
        <i *ngIf="imgUrl && !deleteStatus"  class="material-icons remove-img" style="color:red;" aria-hidden="true" (click)="removeSingle(imgUrl)">delete</i>
        <img [src]="imgUrl" height="200" *ngIf="imgUrl">
    </div>
    <div class="uload-image" *ngIf="!imgUrl">
        <div class="upload">
            <span>
        <i class="material-icons">attach_file</i>
      </span>
            <input #file type="file" accept='image/*' (change)="preview(file.files)" class="file-input" />
        </div>
        <span style="color:red;" *ngIf="imgErrorMessage">{{imgErrorMessage}}</span>
    </div>
</div>

<div class="rotating-images row" *ngIf="locationStatus == 'rotatingImages'" dnd-sortable-container [sortableData]="imgArray">
    <!-- <ul class="list-group" > -->
    <div class="uploaded-image" *ngFor='let url of imgArray;let i = index' dnd-sortable [sortableIndex]="i">
        <div class="inner-image-wrapper file-upload-wrapper">
            <i class="material-icons remove-img" style="color:red;" aria-hidden="true" (click)="removeDomImage(i,url)">delete</i>
            <img [src]="url">
        </div>
    </div>
    <!-- </ul> -->
    <div class="uload-image custom-upload">
        <div class="upload" style="cursor:pointer">
            <span style="cursor:pointer">
        <i class="material-icons" style="cursor:pointer;">image</i><br>
        {{ 'AddImage'|translate }}
      </span>
            <input style="cursor:pointer" #file type="file" accept='image/*' (click)="file.value = null" value="" (change)="preview(file.files)" />
            <!--This way every time you click on file input it will clear it's value so even if you select the same file change will fire.-->
            <span style="color:red;" *ngIf="imgErrorMessage">{{imgErrorMessage}}</span>

        </div>
    </div>

</div>

<div class="single-image" *ngIf="locationStatus == 'singleInstantUpload'">
    <div class="profile-image" *ngIf="imgUrl">
        <i class="material-icons remove-img" style="color:red;" aria-hidden="true" (click)="removeDomImage(i,url)">delete</i>
        <img [src]="imgUrl" height="200" *ngIf="imgUrl">
    </div>
    <div class="uload-image">
        <div class="upload">
            <span>
        <i class="material-icons">attach_file</i>
      </span>
            <input #file type="file" accept='image/*' (click)="file.value = null" value="" (change)="preview(file.files)" />
        </div>
        <span style="color:red;" *ngIf="imgErrorMessage">{{imgErrorMessage}}</span>
    </div>
</div>

<div class="single-image internal-uploadation cursor-pointer" *ngIf="locationStatus === 'parentLocationStatus'">
    <div class="uload-image">
        <div class="upload">
            <input #fileInput type="file" class="cursor-pointer" accept='image/*'  (change)="preview(fileInput.files)" style="display:none;"  />
            <span>Image </span>
        </div>
        <span style="color:red;" *ngIf="imgErrorMessage">{{imgErrorMessage}}</span>
    </div>
</div>

<div class="single-image internal-uploadation mt-1 cursor-pointer" *ngIf="locationStatus === 'pdfUploadInternal'">
    <div class="uload-image" *ngIf="!imgUrl">
        <div class="upload">
            <input #pdfInput type="file" class="cursor-pointer" accept="application/pdf" (change)="pdfPreview(pdfInput.files)" style="display:none;"  />
            <span>PDF</span>
        </div>
        <!-- <span class="mt-2 d-block" style="color:red;" *ngIf="imgErrorMessage">{{imgErrorMessage}}</span> -->
    </div>
</div>


<div class="single-image" *ngIf="locationStatus == 'pdfUpload'">
    <div class="uploaded-file-pdf d-flex justify-content-between align-items-center" *ngIf="imgUrl">
        <div>
            <span class="file-type-img"><img src="assets/images/pdf-icon.png" alt="Pdf Icon"></span>
            <span class="file-name" *ngIf="fileName">{{fileName}}</span>
        </div>
        <i class="material-icons" (click)="delete(imgUrl,'pdf')">close</i>
    </div>
    <div class="uload-image custom-upload-div custom-upload position-static" *ngIf="!imgUrl">
        <div class="upload">
            <span class="align-items-center justify-content-center"><i
          class="material-icons d-block">file_upload</i>{{ 'ClickToUploadPdfFile'|translate }}</span>
            <input #file type="file" (change)="pdfPreview(file.files)"  accept="application/pdf"/>
        </div>
        <span class="mt-2 d-block" style="color:red;" *ngIf="imgErrorMessage">{{imgErrorMessage}}</span>
    </div>
</div>

<div class="single-image" *ngIf="locationStatus === 'editProfile'">
    <div class="profile-image">
        <img [src]="imgUrl" height="200" *ngIf="imgUrl">
    </div>
    <div class="uload-image" *ngIf="!imgUrl">
        <div class="upload">
            <span>
        <i class="material-icons">attach_file</i>
      </span>
            <input #file type="file" accept='image/*' (change)="preview(file.files)" class="file-input" />
        </div>
        <span style="color:red;" *ngIf="imgErrorMessage">{{imgErrorMessage}}</span>
    </div>
</div>
