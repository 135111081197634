import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { GlobalService } from "../../services/global.service";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig
} from "@angular/material/dialog";
import { MasterService } from "../../services/master.service";
import { Globals } from "../../globals";
import { takeUntil } from "rxjs/operators";
import {
  RoomTypeModel,
  RoomTypeArrayModel
} from "../../models/common-request-response";
import { AddNewRoomTypeModalComponent } from "../add-new-room-type-modal/add-new-room-type-modal.component";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmDialogModalComponent } from "../confirm-dialog-modal/confirm-dialog-modal.component";
import { SharedService } from "src/app/shared.service";

@Component({
  selector: "app-update-room-type-modal",
  templateUrl: "./update-room-type-modal.component.html",
  styleUrls: ["./update-room-type-modal.component.scss"]
})
export class UpdateRoomTypeModalComponent implements OnInit {
  public listRoomtypes: RoomTypeArrayModel = new RoomTypeArrayModel();

  public setPopupStatus = true;
  public lang = this.globals.langSaveProperty;
  constructor(
    private fb: UntypedFormBuilder,
    public globalService: GlobalService,
    private dialogRef: MatDialogRef<UpdateRoomTypeModalComponent>,
    private masterService: MasterService,
    public globals: Globals,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    public sharedService: SharedService
  ) {}

  ngOnInit() {
    this.listRoomtypes = this.globalService.jsonConvert.deserializeObject(
      this.data,
      RoomTypeArrayModel
    );
  }
  ngAfterViewChecked() {
    // this.fetchRoomtypes(this.globalService.getLangSaveProperty());
  }

  ngOnDestroy() {}

  fetchRoomtypes(langSave) {
    this.masterService
      .fetchRoomtypes({ LangSave: langSave })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            let responseObject = { RoomTypeLists: response.Data };
            this.listRoomtypes = this.globalService.jsonConvert.deserializeObject(
              responseObject,
              RoomTypeArrayModel
            );
          } else {
            this.globalService.showLoader = false;
            // this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          // this.globalService.handleApiError(error);
        }
      );
  }

  switchLanguage(event) {
    this.fetchRoomtypes(event);
    this.lang = event;
  }

  closeModal() {
    this.dialogRef.close();
  }

  edit(index, id) {
    this.masterService
      .showSingleRoomType({
        LangSave: "en",
        RoomTypeId: id
      })
      .pipe(takeUntil(this.globalService.componentDestroyed(this)))
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            let dialogRef = this.dialog.open(AddNewRoomTypeModalComponent, {
              width: "440px",
              height: "auto",
              panelClass: "add-image",
              disableClose: true,
              data: response.Data
            });

            const sub = dialogRef.componentInstance.onAdd.subscribe(
              (data: any) => {
                //this.roomTypes.push({'id':5,'value':data});
                // this.updateSingleAmenitie(data);

                // console.log(data.name);
                // console.log(this.listRoomtypes.RoomTypeLists[index].name);
                this.listRoomtypes.RoomTypeLists[index].name = data.name;

                this.sharedService.updateChangeEvent.emit({
                  id: id,
                  name: data.name
                });
              }
            );

            dialogRef.afterClosed().subscribe(() => {
              this.fetchRoomtypes(this.lang);
              sub.unsubscribe();
            });
          } else {
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  delete(index, id) {
    // this.listRoomtypes.RoomTypeLists.splice(index, 1);

    //Dialog Confirmation for Delete Image Gallery
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass ='delete-dialog';
    dialogConfig.data = {
      id: 1,
      title: this.translate.instant("DeleteCustomMessage")
    };
    const dialogRef = this.dialog.open(
      ConfirmDialogModalComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      // console.log("Dialog was closed")
      // console.log(result)

      if (result) {
        this.masterService
          .deleteRoomType({
            RoomTypeId: id
          })
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe(
            response => {
              if (response.Status) {
                if (response.Data) {
                  //this.listRoomtypes.splice(i, 1);
                  // this.listRoomtypes.RoomTypeLists.splice(index, 1);
                  this.fetchRoomtypes(this.lang);

                  //Update the Parent Manage Room List
                  this.sharedService.deleteChangeEvent.emit(id);
                }
                this.globalService.showLoader = false;
              } else {
                this.globalService.handleApiError(response);
              }
            },
            error => {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(error);
            }
          );
      }
    });
  }
}

interface RoomsTypeInterface {
  id: string;
  name: string;
}
