// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  host: 'https://cityhotelxs-v2-api.symple.co.in',
  apiUrl: 'https://cityhotelxs-v2-api.symple.co.in',
  langSave: { Property: 'en', Value: 'English' },
  langView: 'en',
  SubDomainLocal: 'cityhotelxs.softobiz.net'
};
