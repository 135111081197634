import {
  Component,
  OnInit,
  ɵConsole,
  Output,
  EventEmitter,
  AfterViewInit
} from "@angular/core";
import { ElementRef, Input, ViewChild } from "@angular/core";
import { HttpClient, HttpEventType } from "@angular/common/http";
import { GlobalService } from "../../services/global.service";
import { AmazonWebService } from "../../services/amazon-web.service";
import { HotelProfileModel } from "../../models/request/hotelProfile.model";
import { ResultModel } from "src/app/core/models/response/base.model";
import { EventModel } from "../../models/response/event.model";
import { Globals } from "../../globals";
import { MatDialogConfig,  MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmDialogModalComponent } from "../../modals/confirm-dialog-modal/confirm-dialog-modal.component";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { SharedService } from "src/app/shared.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"]
})
export class FileUploadComponent implements OnInit {
  uploadSubscribe$;
  deletSubscribe$;
  public hotelProfileModel: HotelProfileModel = new HotelProfileModel();
  public eventModel: EventModel = new EventModel();
  public progress: { percentage: number } = { percentage: 0 };
  public imagePath;
  public imgErrorMessage: string;
  @Input() imgUrl: any;
  @Output() outImgUrl= new EventEmitter();
  @Input() deleteStatus: false;
  @Input() imgArray = [];
  @Output() imageUpload = new EventEmitter();
  @Output() deletedImage = new EventEmitter();
  @Output() upload = new EventEmitter();
  @Input() locationStatus: string;
  @Input() locationUploadType: string;
  @Input() uploadFolder: string; //Uploaded Folder
  @Input() fileName: string;
  @ViewChild('file') public file: ElementRef;
  @ViewChild('fileInput') public fileInput: ElementRef; 
  @ViewChild('pdfInput') public pdfInput: ElementRef; 
  isFileInputClicked: boolean = false;
  isPdfInputClicked: boolean = false;
     public uploadFolderArray = []; //To push Upload Folder
  constructor(
    public globalService: GlobalService,
    private amazonWebService: AmazonWebService,
    public globals: Globals,
    private dialog: MatDialog,
    private translate: TranslateService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    console.log(this.locationStatus);

    console.log(this.imgUrl);
    //if location is
    // this.globalService.showLoader = true;
  }

  ngOnDestroy() {
    if (this.uploadSubscribe$) {
      this.uploadSubscribe$.unsubscribe();
    }

    if (this.deletSubscribe$) {
      this.deletSubscribe$.unsubscribe();
    }
  }
  // ngAfterViewInit(){
  //   this.fileInput;
  //   this.pdfInput
  // }
  triggerFileInput(event: MouseEvent, fileInput: HTMLElement) {
    event.stopPropagation();
    if (!this.isFileInputClicked) {
      this.isFileInputClicked = true;
      fileInput.click();
    }
  }

  triggerPdfFileInput(event: MouseEvent, pdfInput: HTMLElement) {
    event.stopPropagation();
    if (!this.isPdfInputClicked) {
      this.isPdfInputClicked = true;
      pdfInput.click();
    }
  }
  removeDuplicateUsingFilter(arr) {
    let unique_array = arr.filter(function(elem, index, self) {
      return index == self.indexOf(elem);
    });
    return unique_array;
  }

  pdfPreview(files) {
    this.imgErrorMessage = "";
    if (files.length === 0) return;

    var mimeType = files[0].type;
    // console.log(mimeType);
    // console.log(files[0]);
    if (mimeType.match(/pdf\/*/) == null) {
      this.imgErrorMessage = "Only pdf are supported.";
      return;
    }
    this.fileName = files[0].name;
    var reader = new FileReader();

    if (this.locationStatus == "pdfUpload") {
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = _event => {
        this.imgUrl = reader.result;
        this.upload.emit(files);
      };
    };
    if (this.locationStatus ==="pdfUploadInternal") {
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = _event => {
        this.imgUrl = reader.result;
        this.outImgUrl.emit(this.imgUrl);
        this.isPdfInputClicked = false;
        this.upload.emit(files);
      };
    }
  }
  reset() {
    this.imgUrl = ''; // Clear image URL
    if (this.fileInput) {
      this.fileInput.nativeElement.value = ''; // Clear any previously selected file
    }
    if (this.pdfInput) {
      this.pdfInput.nativeElement.value = ''; // Clear any previously selected file
    }
  }

  preview(files) {
    this.imgErrorMessage = "";
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.imgErrorMessage = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    if (this.locationStatus == "singleImage") {
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = _event => {
        this.imgUrl = reader.result;
        // this.currentFileUpload = files.item(0);
        // this.currentFileName = files[0].name;
        this.outImgUrl.emit(this.imgUrl);
        this.imageUpload.emit(files); //Fire Child Event
        // console.log("New Image Load "+this.currentFileName);
        this.clearDom();
      };
    };
    if (this.locationStatus === "parentLocationStatus") {
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = _event => {
        this.imgUrl = reader.result;
        // this.currentFileUpload = files.item(0);
        // this.currentFileName = files[0].name;
        this.outImgUrl.emit(this.imgUrl);
        this.imageUpload.emit(files); //Fire Child Event
        // console.log("New Image Load "+this.currentFileName);
        this.isFileInputClicked = false;
        this.clearDomfile();
        this.isFileInputClicked = false;
      };
    }

    if (this.locationStatus == "imageEdit") {
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = _event => {
        this.imgUrl = reader.result;
        this.imageUpload.emit(files);
      };
    }
    if (this.locationStatus == "editProfile") {
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = _event => {
        this.imgUrl = reader.result;
        this.imageUpload.emit(files);
      };
    }

    if (this.locationStatus == "singleInstantUpload") {
      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = _event => {
        this.imgUrl = reader.result;
        // this.currentFileUpload = files.item(0);
        // this.currentFileName = files[0].name;

        this.imageUpload.emit(files); //Fire Child Event
        // console.log("New Image Load "+this.currentFileName);
      };
    }

    if (this.locationStatus == "rotatingImages") {
      this.uploadFolderArray = [];
      this.uploadFolderArray.push(this.uploadFolder);

      // const findIndexUploadFolder = this.uploadFolderArray.findIndex(
      //   //Check Index Exists
      //   e => e === this.uploadFolder
      // );

      // if (findIndexUploadFolder === -1) {
      //   this.uploadFolderArray = [];
      //   //If Index does not exists
      //   //Push Image To the Array
      //   this.uploadFolderArray.push(this.uploadFolder);
      //   this.imgArray = []; //emptied the Image Array
      // }

      this.imagePath = files;
      reader.readAsDataURL(files[0]);
      reader.onload = _event => {
        this.imgUrl = reader.result;
        // this.currentFileUpload = files.item(0);
        // this.currentFileName = files[0].name;

        this.globalService.showLoader = true;
        this.amazonWebService.uploadObject(
          files[0].name,
          files.item(0),
          "private",
          this.uploadFolder
        );

        this.uploadSubscribe$ = this.amazonWebService.processCompleted
          .pipe(takeUntil(this.globalService.componentDestroyed(this)))
          .subscribe(
            response => {
              if (response.data.Location) {
                this.globalService.showLoader = false;
                const findIndexUploadFolder = this.uploadFolderArray.findIndex(
                  //Check Index Exists
                  e => e === response.data.FolderName
                );
                this.isFileInputClicked = false;
                // console.log(this.uploadFolder);
                // console.log(this.uploadFolderArray);
                // console.log(this.imgArray);
                // console.log("Response folder");
                // console.log(response.data.FolderName);

                if (this.uploadFolder === response.data.FolderName) {
                  //Check If Upload Folder is the Same
                  if (this.imgArray) {
                    // Check If array exists
                    const index = this.imgArray.findIndex(
                      //Check Index Exists
                      e =>
                        e ===
                        this.globals.S3Url +
                          this.globalService.getHostname() +
                          "/" +
                          this.uploadFolder +
                          "/" +
                          response.data.Location
                    );

                    if (index === -1) {
                      //If Index does not exists
                      //Push Image To the Array
                      this.imgArray.push(
                        this.globals.S3Url +
                          this.globalService.getHostname() +
                          "/" +
                          this.uploadFolder +
                          "/" +
                          response.data.Location
                      );
                    }
                  } else {
                    this.imgArray.push(
                      this.globals.S3Url +
                        this.globalService.getHostname() +
                        "/" +
                        this.uploadFolder +
                        "/" +
                        response.data.Location
                    );
                  }

                  //Check Image Duplicacy
                  this.imgArray = this.removeDuplicateUsingFilter(
                    this.imgArray
                  );

                  //Emit the Image Array
                  this.imageUpload.emit({
                    imgArray: this.imgArray,
                    uploadFolder: this.uploadFolder
                  });
                }
              }
            },
            error => {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(error);
              return false;
            }
          );
      };
    }
  }
  removeSingleImageFromParent(imgUrl){
    let splitImgUrl = imgUrl.split("/");
    let fetchImage = splitImgUrl[splitImgUrl.length - 1];
    let imgType = fetchImage.split(".").pop();
    this.globalService.showLoader = true;

        //Delete Api Request With Aws Signed Url
        this.amazonWebService.deleteObject(
          fetchImage,
          imgType,
          "private",
          this.uploadFolder
        );

        // Subscribe To Delete event after Success Delete Image From Bucket
        this.deletSubscribe$ = this.amazonWebService.deleteProcessCompleted.subscribe(
          response => {
            this.globalService.showLoader = false;
            this.imgUrl = "";
            this.sharedService.deleteSingleImage.emit(this.uploadFolder);
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
            return false;
          }
        );
  }
  removeSingle(imgUrl) {
    //Split ImageUrl to find image name and type
    let splitImgUrl = imgUrl.split("/");
    let fetchImage = splitImgUrl[splitImgUrl.length - 1];
    let imgType = fetchImage.split(".").pop();

    //Dialog Confirmation for Delete Image Gallery
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "delete-dialog";
    dialogConfig.data = {
      id: 1,
      title: this.translate.instant("DeleteCustomMessage")
    };

    const dialogRef = this.dialog.open(
      ConfirmDialogModalComponent,
      dialogConfig
    );
    console.log(this.uploadFolder);
    console.log(fetchImage);
    console.log(imgType);

    dialogRef.afterClosed().subscribe(result => {
      // console.log("Dialog was closed")
      // console.log(result)
      if (result) {
        this.globalService.showLoader = true;

        //Delete Api Request With Aws Signed Url
        this.amazonWebService.deleteObject(
          fetchImage,
          imgType,
          "private",
          this.uploadFolder
        );

        // Subscribe To Delete event after Success Delete Image From Bucket
        this.deletSubscribe$ = this.amazonWebService.deleteProcessCompleted.subscribe(
          response => {
            this.globalService.showLoader = false;
            this.imgUrl = "";
            this.sharedService.deleteSingleImage.emit(this.uploadFolder);
            this.deletedImage.emit(true);
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
            return false;
          }
        );
      }
    });
  }

  removeDomImage(index, imgUrl) {
    // if (this.imgArray.length > 0) {
    //   this.imgArray.splice(index, 1);
    // }
    //Split ImageUrl to find image name and type
    let splitImgUrl = imgUrl.split("/");
    let fetchImage = splitImgUrl[splitImgUrl.length - 1];
    let imgType = fetchImage.split(".").pop();

    //Dialog Confirmation for Delete Image Gallery
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = "delete-dialog";
    dialogConfig.data = {
      id: 1,
      title: this.translate.instant("DeleteCustomMessage")
    };
    const dialogRef = this.dialog.open(
      ConfirmDialogModalComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      // console.log("Dialog was closed")
      // console.log(result)

      if (result) {
        this.globalService.showLoader = true;
        this.imgArray.splice(index, 1);
        //Delete Api Request With Aws Signed Url
        this.amazonWebService.deleteObject(
          fetchImage,
          imgType,
          "private",
          this.uploadFolder
        );

        // Subscribe To Delete event after Success Delete Image From Bucket
        this.deletSubscribe$ = this.amazonWebService.deleteProcessCompleted.subscribe(
          response => {
            this.globalService.showLoader = false;
            //Check If Upload Folder is the Same as Delete folder
            if (this.uploadFolder === response.data.FolderName) {
              if (this.imgArray) {
                // Check If array exists
                const exists = this.imgArray.findIndex(
                  //Check Index Exists
                  e =>
                    e ===
                    this.globals.S3Url +
                      this.globalService.getHostname() +
                      "/" +
                      this.uploadFolder +
                      "/" +
                      response.data.Location
                );
                console.log("Exists>>>>>>" + exists);
                if (exists === -1) {
                  //If Image not exists

                  //Check Image Duplicacy
                  this.imgArray = this.removeDuplicateUsingFilter(
                    this.imgArray
                  );

                  //Emit the Image Array
                  this.imageUpload.emit({
                    imgArray: this.imgArray,
                    uploadFolder: this.uploadFolder
                  });
                } else {
                  // this.imgArray.splice(index, 1);
                }
              } else {
                //Remove  Image From Dom
                // this.imgArray.splice(index, 1);
              }
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
            return false;
          }
        );
      }
    });
  }

  delete(imgUrl, location) {
    let splitImgUrl = imgUrl.split("/");
    let fetchImage = splitImgUrl[splitImgUrl.length - 1];
    let imgType = fetchImage.split(".").pop();

    //Dialog Confirmation for Delete Image Gallery
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      id: 1,
      title: this.translate.instant("DeleteCustomMessage")
    };
    const dialogRef = this.dialog.open(
      ConfirmDialogModalComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe(result => {
      // console.log("Dialog was closed")
      // console.log(result)

      if (result) {
        this.globalService.showLoader = true;

        //Delete Api Request With Aws Signed Url
        this.amazonWebService.deleteObject(
          fetchImage,
          imgType,
          "private",
          location
        );

        // Subscribe To Delete event after Success Delete Image From Bucket
        this.deletSubscribe$ = this.amazonWebService.deleteProcessCompleted.subscribe(
          response => {
            if (response.data.Filename) {
              this.globalService.showLoader = false;
              this.sharedService.deletePdfEvent.emit(imgUrl);
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
            return false;
          }
        );
      }
    });
  }


  /**
   * Clears dom
   */
  clearDom(){
    this.file.nativeElement.value = "";

  }
  clearDomfile(){
    this.fileInput.nativeElement.value = "";
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   if (this.imgArray && this.imgArray.length) {
  //     moveItemInArray(this.imgArray, event.previousIndex, event.currentIndex);
  //   }
  // }
}
