import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import {
  ManageGalleryModel,
  GalleryLangModel,
  ManageGalleryArrayModel
} from "../../models/request/manageGallery.model";
import { NgForm } from "@angular/forms";
import { AmazonWebService } from "../../services/amazon-web.service";
import { GlobalService } from "../../services/global.service";
import { ManageGalleryService } from "../../services/manage-gallery.service";
import { MasterService } from "../../services/master.service";
import { Globals } from "../../globals";
import { MatDialogRef } from "@angular/material/dialog";

import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SharedService } from "src/app/shared.service";

@Component({
  selector: "app-add-new-image-modal",
  templateUrl: "./add-new-image-modal.component.html",
  styleUrls: ["./add-new-image-modal.component.scss"]
})
export class AddNewImageModalComponent implements OnInit {
  saveSubscribe$;
  updateSubscribe$;
  countGallery$;
  public manageGalleryModel: ManageGalleryModel = new ManageGalleryModel();
  public galleryLangModel: GalleryLangModel = new GalleryLangModel();
  public progress: { percentage: number } = { percentage: 0 };
  public imagePath;
  public imgURL: any;
  public message: string;
  public uploadStatus: boolean = false;
  public imageReadStatus: boolean = false;

  public currentFileUpload: File;
  public signedUrl: string;
  public currentFileName: string;
  public updateButtonStatus: boolean = false;

  public imageRequired: boolean = false;

  public checkGalleryCount: Number = 0;
  public manageGalleryArrayModel: ManageGalleryArrayModel = new ManageGalleryArrayModel();
  @ViewChild("Form", { read: NgForm }) custom: any;

  constructor(
    public globalService: GlobalService,
    private amazonWebService: AmazonWebService,
    private masterService: MasterService,
    private manageGalleryService: ManageGalleryService,
    public globals: Globals,
    private dialogRef: MatDialogRef<AddNewImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    //  console.log("Check Data"+JSON.stringify(this.data));
    if (this.data) {
      this.imageReadStatus = true;
      this.updateButtonStatus = true;
      this.manageGalleryModel = this.globalService.jsonConvert.deserializeObject(
        this.data,
        ManageGalleryModel
      );
      console.log("Image Detail Initialize" + this.manageGalleryModel);
      this.imgURL = this.manageGalleryModel.Image;

      if (this.manageGalleryModel.ImageTitle.length) {
        this.galleryLangModel = this.manageGalleryModel.ImageTitle[0];
      }
    }

    let reqModel = {
      Lang: "en",
      Subdomain: this.globalService.getHostname(),
      HomeParam: 0
    };

    this.manageGalleryService.getImageGalleryAdmin(reqModel).subscribe(
      response => {
        if (response.Status) {
          this.globalService.emitPermissions(response.AdminPermissions);
          // console.log(response);
          this.manageGalleryArrayModel = this.globalService.jsonConvert.deserializeObject(
            response.Data,
            ManageGalleryArrayModel
          );

          this.checkGalleryCount = this.manageGalleryArrayModel.GalleryImages.length;
        } else {
          // this.showLoader = false;
          this.globalService.handleApiError(response);
        }
      },
      error => {
        // this.showLoader = false;
        this.globalService.handleApiError(error);
      }
    );
  }

  ngOnDestroy() {
    // console.log("Destroy Angular Component");
    if (this.saveSubscribe$) {
      this.saveSubscribe$.unsubscribe();
    }

    if (this.updateSubscribe$) {
      this.updateSubscribe$.unsubscribe();
    }

    if (this.countGallery$) {
      this.countGallery$.unsubscribe();
    }
  }

  switchLanguage(event) {
    if (this.manageGalleryModel._id) {
      this.globalService.showLoader = true;
      this.manageGalleryService
        .getSingleImageGallery({ Id: this.manageGalleryModel._id, Lang: event })
        .subscribe(
          response => {
            if (response.Status) {
              this.globalService.showLoader = false;

              this.manageGalleryModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                ManageGalleryModel
              );
              if (this.manageGalleryModel.ImageTitle.length) {
                this.galleryLangModel = this.manageGalleryModel.ImageTitle[0];
              } else {
                this.galleryLangModel = { Lang: event, Value: "" };
              }
            } else {
              this.globalService.showLoader = false;
              this.globalService.handleApiError(response);
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
          }
        );
    }
  }

  preview(files) {
    this.imageReadStatus = true;
    this.uploadStatus = true;
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = _event => {
      this.imgURL = reader.result;
      this.currentFileUpload = files.item(0);
      this.currentFileName = files[0].name;
      // console.log("New Image Load "+this.currentFileName);
    };
  }

  removeDomImage() {
    this.imageReadStatus = false;
    this.uploadStatus = false;
  }

  saveGallery(manageGalleryModel, galleryLangModel) {
    this.globalService.showLoader = true;
    this.manageGalleryService
      .addImageGallery({
        Data: manageGalleryModel,
        ImageTitle: galleryLangModel
      })
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;
            // this.dialogRef.close();
            this.masterService.galleryData.emit(response.Data);
            this.globalService.handleSuccessMessage(response.Message);
            // this.router.navigateByUrl('/RefrshComponent', {skipLocationChange: true}).then(()=>
            // this.router.navigate(["/hotel/admin/manage/imageGallery"]));
            // this.router.navigate(["/hotel/admin/manage/imageGallery"]);

            if (response.Data) {
              this.imageReadStatus = true;
              this.updateButtonStatus = true;
              this.manageGalleryModel = this.globalService.jsonConvert.deserializeObject(
                response.Data,
                ManageGalleryModel
              );
              // console.log("Image Detail Initialize" + this.manageGalleryModel);
              this.imgURL = this.manageGalleryModel.Image;

              if (this.manageGalleryModel.ImageTitle.length) {
                this.galleryLangModel = this.manageGalleryModel.ImageTitle[0];
              }
            }
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  updateGallery(manageGalleryModel, galleryLangModel) {
    this.globalService.showLoader = true;
    this.manageGalleryService
      .updateImageGallery({
        Lang: this.masterService.getLangSaveProperty(),
        Data: manageGalleryModel,
        DataLang: galleryLangModel
      })
      .subscribe(
        response => {
          if (response.Status) {
            this.globalService.showLoader = false;

            this.masterService.galleryData.emit(response.Data);
            // this.dialogRef.close();
            this.globalService.handleSuccessMessage(response.Message);
            // this.router.navigateByUrl('/RefrshComponent', {skipLocationChange: true}).then(()=>
            // this.router.navigate(["/hotel/admin/manage/imageGallery"]));
          } else {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(response);
          }
        },
        error => {
          this.globalService.showLoader = false;
          this.globalService.handleApiError(error);
        }
      );
  }

  gallerySubmit(form: NgForm) {
    if (!this.uploadStatus) {
      this.imageRequired = true;
      return false;
    }

    this.galleryLangModel.Lang = this.masterService.getLangSaveProperty();
    if (!form.invalid) {
      if (this.uploadStatus) {
        this.uploadStatus = false;
        this.globalService.showLoader = true;
        this.amazonWebService.uploadObject(
          this.currentFileName,
          this.currentFileUpload,
          "private",
          "imageGallery"
        );
        this.saveSubscribe$ = this.amazonWebService.processCompleted.subscribe(
          response => {
            if (response.data.Location) {
              //update Image Model
              this.manageGalleryModel.Image =
                this.globals.S3Url +
                this.globalService.getHostname() +
                "/imageGallery/" +
                response.data.Location;

              this.manageGalleryModel.Order = this.checkGalleryCount;
              this.saveGallery(this.manageGalleryModel, this.galleryLangModel);
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
            return false;
          }
        );
      }
    } else {
      this.globalService.showLoader = false;
      this.globalService.validateFormFields(form.form);
    }
  }

  updateGallerySubmit(form: NgForm) {
    // console.log("Form Values "+JSON.stringify(form.value));
    if (!this.imageReadStatus) {
     let  msg="Please update the image first."
    this.globalService.handleErrorMessage(msg)
      return;
  }
    // console.log("Update Button Click "+JSON.stringify(this.manageGalleryModel));
    this.galleryLangModel.Lang = this.masterService.getLangSaveProperty();
    if (!form.invalid) {
      if (this.uploadStatus) {
        this.uploadStatus = false; //Update Image status
        this.globalService.showLoader = true;
        this.amazonWebService.uploadObject(
          this.currentFileName,
          this.currentFileUpload,
          "private",
          "imageGallery"
        );
        this.updateSubscribe$ = this.amazonWebService.processCompleted.subscribe(
          response => {
            if (response.data.Location) {
              //update Image Model
              this.manageGalleryModel.Image =
                this.globals.S3Url +
                this.globalService.getHostname() +
                "/imageGallery/" +
                response.data.Location;

              //  console.log("Binding Models ManagGallery "+JSON.stringify(this.manageGalleryModel));
              //  console.log("Binding Models Gallery Model"+ JSON.stringify(this.galleryLangModel));
              this.updateGallery(
                this.manageGalleryModel,
                this.galleryLangModel
              );
            }
          },
          error => {
            this.globalService.showLoader = false;
            this.globalService.handleApiError(error);
            return false;
          }
        );
      } else {
        this.updateGallery(this.manageGalleryModel, this.galleryLangModel);
      }
    } else {
      this.globalService.showLoader = false;
      this.globalService.validateFormFields(form.form);
    }
  }

  closeModal() {
    //console.log(this.custom);
    //  this.custom.reset();
    this.dialogRef.close();
  }
}
