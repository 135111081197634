<div class="login-wrapper">
    <div class="login-contaier">
        <div class="logo-and-info">
            <a href="javascript:void(0);" routerLink="/" *ngIf="manageBrandingModel && manageBrandingModel.Logo;else other_logo"><img [src]="manageBrandingModel.Logo" [alt]="manageBrandingModel.Name"></a>
            <ng-template #other_logo>
                <a href="javascript:void(0);" routerLink="/"><img src="" alt="Villa La Fontera"></a>
            </ng-template>
            <!-- <img src="assets\images\website-logo.png" alt="Villa La Fontera" /> -->
        </div>

        <div class="form-wrapper" *ngIf="forgetPassword">
            <form class="login-form" #forgetForm="ngForm">
                <mat-form-field>
                    <mat-label>{{'Email'|translate}} / {{ 'Username'|translate }}</mat-label>
                    <input matInput type="text" [ngModel]="hotelLoginModel.Username" name="Username" placeholder="Enter your Email" required>
                </mat-form-field>
                <button type="button" class="btn btn-success btn-lg submit-btn" (click)="forgetPass(forgetForm)">{{'Submit'|translate}}</button>
            </form>
        </div>

        <div class="form-wrapper" *ngIf="resetAuth">
            <form class="login-form" #verifyForm="ngForm">
                <mat-form-field>
                    <mat-label> {{ 'VerifyCode'|translate }}</mat-label>
                    <input matInput type="text" [ngModel]="hotelLoginModel.Secret" name="Secret" placeholder="Enter code here" required>
                </mat-form-field>
                <button type="button" class="btn btn-success btn-lg submit-btn" (click)="verifyCode(verifyForm)">{{'Verify'|translate}}</button>
                <button type="button" class="btn btn-primary btn-lg submit-btn mt-3 w-100" (click)="resend()">{{'Resend'|translate}}</button>
            </form>
        </div>


        <div class="form-wrapper" *ngIf="resetPassword">
            <form class="login-form" #form="ngForm">
                <mat-form-field>
                    <mat-label>{{ 'EnterNewPassword'|translate }}</mat-label>
                    <input matInput type="text" [ngModel]="hotelLoginModel.Password" name="Password" placeholder="Enter a new Password" required>
                </mat-form-field>
                <button type="button" class="btn btn-success btn-lg submit-btn" (click)="resetPass(form)">{{'Reset'|translate}}</button>
            </form>
        </div>

        <p class="text-center help-link mt-3"><a href="javascript:;" routerLink="/hotel">{{ 'Backtologin'|translate }}</a></p>
    </div>
</div>